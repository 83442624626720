import './App.css';

import React, { useEffect, useState } from 'react';

import Errorroutes from './routes/Router2';
import Themeroutes from './routes/Router';
import axios from 'axios';
import { useRoutes } from 'react-router-dom';

const App = () => {
  const routing = useRoutes(Themeroutes);
  const routing2 = useRoutes(Errorroutes);
  let [booleanyn, booleanynSet] = useState(false);

  const setyn = async () => {
    try {
      const ip_info = await axios.post('https://mystoredev.h-brick.com/join/getIp');
      // console.log('ip_info', ip_info)
      if (ip_info.data.ip === '112.217.177.154' || ip_info.data.ip === '112.217.177.157') {
        booleanynSet(true);
      } else {
        booleanynSet(false);
      }
    } catch (error) {
      console.debug('아이피 체크 실패::::', error);
    }

    // console.log(data.IPv4);
  };

  useEffect(() => {
    setyn();
  }, []);

  // if(booleanyn){
  //   return <div className="dark">{routing}</div>;
  // }else{
  //   return <div className="dark">{routing2}</div>;
  // }

  return <React.Fragment>{booleanyn && <div className="dark">{booleanyn ? routing : routing2}</div>}</React.Fragment>;
};

export default App;
